import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/dateManager';

import { useAdvice } from 'hooks/useAdvice';
import useCeremonyLocation from 'hooks/useCeremonyLocation';
import { useVigilLocationDescription } from 'hooks/useVigilLocation';

import { SendWhatsappModalContentComponent } from './types';

export const SendWhatsappModalContent: FC<SendWhatsappModalContentComponent> = (
  { number, recordId, onUpdateLoadingData },
) => {
  const {
    setCityCode: setVigilCity,
    setCode: setVigilLocation,
    description: vigilDescription,
  } = useVigilLocationDescription();
  const { fetchAdviceInfo, adviceInfo, adviceLoading } = useAdvice();
  const {
    getCeremonyLocation,
  } = useCeremonyLocation(adviceInfo?.ceremonyServiceDto?.ceremonyAddress?.city);
  const { t } = useTranslation();
  const [hasVigilInfo, setHasVigilInfo] = useState<boolean>(false);
  const [hasCeremoniInfo, setHasCeremonyInfo] = useState<boolean>(false);

  useEffect(
    () => {
      if (adviceInfo?.vigilServiceDto?.address?.city && adviceInfo?.vigilServiceDto?.location) {
        setVigilCity(adviceInfo?.vigilServiceDto?.address?.city);
        setVigilLocation(adviceInfo?.vigilServiceDto?.location);
      }

      setHasVigilInfo(!!adviceInfo?.vigilServiceDto?.entryDate
        && !!adviceInfo?.vigilServiceDto?.entryTime
         && !!adviceInfo?.vigilServiceDto?.location
          && !!adviceInfo?.vigilServiceDto?.exitDate
           && !!adviceInfo?.vigilServiceDto?.exitTime);

      setHasCeremonyInfo(!!adviceInfo?.ceremonyServiceDto?.ceremonyDate
        && !!adviceInfo?.ceremonyServiceDto?.ceremonyTime
        && !!adviceInfo?.ceremonyServiceDto?.location);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adviceInfo],
  );

  useEffect(
    () => {
      onUpdateLoadingData(adviceLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adviceLoading],
  );

  useEffect(
    () => {
      fetchAdviceInfo(parseInt(recordId, 10));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <React.Fragment>
      <div>{t('record.whatsapp.whatsappConfirmation', { number })}</div>
      <div>&nbsp;</div>
      {adviceInfo && hasVigilInfo && (
        <div>
          {t('record.whatsapp.whatsappConfirmationVigil', {
            fromDate: formatDate(adviceInfo?.vigilServiceDto?.entryDate || ''),
            fromTime: adviceInfo?.vigilServiceDto?.entryTime,
            location: vigilDescription,
            toDate: formatDate(adviceInfo?.vigilServiceDto?.exitDate || ''),
            toTime: adviceInfo?.vigilServiceDto?.exitTime,
          })}
        </div>
      )}
      {adviceInfo && !hasVigilInfo && (
        <div>
          {t('record.whatsapp.whatsappConfirmationNoVigil')}
        </div>
      )}
      {adviceInfo && hasCeremoniInfo && (
        <div>
          {t('record.whatsapp.whatsappConfirmationCeremony', {
            fromDate: formatDate(adviceInfo?.ceremonyServiceDto?.ceremonyDate || ''),
            fromTime: adviceInfo?.ceremonyServiceDto?.ceremonyTime,
            location: getCeremonyLocation(adviceInfo?.ceremonyServiceDto?.location)?.description,
          })}
        </div>
      )}
      {adviceInfo && !hasCeremoniInfo && (
        <div>
          {t('record.whatsapp.whatsappConfirmationNoCeremony')}
        </div>
      )}

    </React.Fragment>
  );
};
