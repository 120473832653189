import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from 'utils/toasts';
import { Nullable } from 'utils/types/Nullable/types';

import { IdentifierTypes } from 'components/base/IdentifierSelector';

import { IAController } from 'modules/ia/api';
import { DocumentDNIScanData } from 'modules/ia/models/DocumentDNIScanData';
import { DocumentDNIScanScore } from 'modules/ia/models/DocumentDNIScanScore';

import { FetchInfoFromPictureParams } from './FetchInfoFromPicture';
import { FetchInfoResponse } from './FetchInfoResponse';
import { FetInfoFromFilesParams } from './FetInfoFromFilesParams';
import { IAHook } from './types';

const emptyResponse = {
  data: {
    address: {
      city: null,
      country: null,
      province: null,
      street: null,
    },
    birthAddress: {
      firstField: null,
      secondField: null,
    },
    birthDate: null,
    firstParent: null,
    firstSurname: null,
    genre: null,
    idCard: null,
    name: null,
    nationality: null,
    secondParent: null,
    secondSurname: null,
  },
  score: {
    address: {
      city: null,
      country: null,
      province: null,
      street: null,
    },
    birthAddress: {
      firstField: null,
      secondField: null,
    },
    birthDate: null,
    firstParent: null,
    firstSurname: null,
    genre: null,
    idCard: null,
    name: null,
    nationality: null,
    secondParent: null,
    secondSurname: null,
  },
};

const toUppercase = (data?: Nullable<string>): Nullable<string> => data?.toUpperCase() || null;

export const useIA = (): IAHook => {
  const { t } = useTranslation();
  const [loading, setloading] = useState<boolean>(false);

  const isEnabledDocumentType = (v: IdentifierTypes) => [
    IdentifierTypes.CIF,
    IdentifierTypes.IDENTIFIER,
    IdentifierTypes.NIE,
    IdentifierTypes.PASSPORT,
  ]
    .includes(v);

  const handleThen = (response: {
     data: DocumentDNIScanData; score: DocumentDNIScanScore;
    }): {
      data: DocumentDNIScanData;
      score: DocumentDNIScanScore; } => {
    setloading(false);
    const { data } = response;

    return {
      data: {
        address: {
          city: data.address.city,
          country: data.address.country,
          province: data.address.province,
          street: toUppercase(data.address.street),
        },
        birthAddress: {
          firstField: data.birthAddress.firstField,
          secondField: data.birthAddress.secondField,
        },
        birthDate: data.birthDate,
        firstParent: toUppercase(data.firstParent),
        firstSurname: toUppercase(data.firstSurname),
        genre: data.genre,
        idCard: toUppercase(data.idCard),
        name: toUppercase(data.name),
        nationality: toUppercase(data.nationality),
        secondParent: toUppercase(data.secondParent),
        secondSurname: toUppercase(data.secondSurname),
      },
      score: response.score,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCatch: ((reason: any) => PromiseLike<never>) | null | undefined = useCallback((e) => {
    setloading(false);
    showErrorToast(t(e.message));
    throw e;
  }, [t]);

  const fetchInfoFromPicture = useCallback(
    (documentType: IdentifierTypes, {
      frontPicture,
      backPicture,
    }: FetchInfoFromPictureParams): FetchInfoResponse => {
      setloading(true);

      if (documentType === IdentifierTypes.IDENTIFIER
        && backPicture) {
        return IAController.ScanDNI({
          back: backPicture, front: frontPicture,
        })
          .then(handleThen)
          .catch(handleCatch);
      } if (documentType === IdentifierTypes.NIE
        && backPicture) {
        return IAController.ScanNIE({
          back: backPicture, front: frontPicture,
        })
          .then(handleThen)
          .catch(handleCatch);
      } if (documentType === IdentifierTypes.CIF) {
        return IAController.ScanCIF({
          front: frontPicture,
        })
          .then(handleThen)
          .catch(handleCatch);
      } if (documentType === IdentifierTypes.PASSPORT) {
        return IAController.ScanPassport({
          front: frontPicture,
        })
          .then(handleThen)
          .catch(handleCatch);
      }

      return Promise.resolve(emptyResponse);
    },
    [handleCatch],
  );

  const fetchInfoFromFiles = useCallback((documentType: IdentifierTypes,
    { file1, file2 }: FetInfoFromFilesParams) => {
    setloading(true);

    if (documentType === IdentifierTypes.IDENTIFIER) {
      return IAController.ScanDNIFromFile({
        file1, file2,
      })
        .then(handleThen)
        .catch(handleCatch);
    } if (documentType === IdentifierTypes.NIE) {
      return IAController.ScanNIEFromFile({
        file1, file2,
      })
        .then(handleThen)
        .catch(handleCatch);
    } if (documentType === IdentifierTypes.CIF) {
      return IAController.ScanCIFFromFile({
        file1,
      })
        .then(handleThen)
        .catch(handleCatch);
    } if (documentType === IdentifierTypes.PASSPORT) {
      return IAController.ScanPassportFromFile({
        file1,
      })
        .then(handleThen)
        .catch(handleCatch);
    }

    return Promise.resolve(emptyResponse);
  },
  [handleCatch]);

  return {
    fetchInfoFromFiles,
    fetchInfoFromPicture,
    isEnabledDocumentType,
    loading,
  };
};
