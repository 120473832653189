const common = {
  accept: 'Aceptar',
  active: 'actiu',
  add: 'Añadir',
  address: 'Dirección',
  adviserComment: 'Comentarios asesor',
  age: 'Edad',
  all: 'Todos',
  articles: 'Artículos',
  back: 'Volver',
  bank: {
    account: 'Nº cuenta',
    bic: 'BIC',
    control: 'Control',
    controlIban: 'Control IBAN',
    country: 'País',
    entity: 'Entidad',
    office: 'Oficina',
  },
  birthday: 'Fecha de nacimiento',
  cancel: 'Cancelar',
  category: 'Categoría',
  channel: 'Canal',
  cif: 'CIF',
  city: 'Ciudad',
  clear: 'Limpiar',
  client: 'Cliente',
  clients: 'Clientes',
  close: 'Cerrar',
  code: 'Código',
  comment: 'Comentario',
  comments: 'Comentarios',
  confirm: 'Confirmar',
  coordinatorComment: 'Comentarios coordinador',
  country: 'País',
  createdDate: 'Fecha de creación',
  date: 'Fecha',
  dateIn: 'Fecha entrada',
  dateOut: 'Fecha salida',
  declarantName: 'Nombre del declarante',
  delete: 'Eliminar',
  description: 'Descripción',
  design: 'Diseño',
  destination: 'Destino',
  document: 'Documento',
  domicile: 'Domicilio',
  edit: 'Editar',
  email: 'Email',
  endDate: 'Fecha fin',
  endHour: 'Hora fin',
  erpId: 'Expediente',
  error: 'Error',
  errorText: 'Se ha producido un error. Vuelva a intentarlo.',
  exitDate: 'Fecha salida',
  exitTime: 'Hora salida',
  firstSurname: 'Primer apellido',
  frontCamera: 'Cámara frontal',
  fullName: 'Nombre y apellidos',
  gender: 'Género',
  go: 'Ir a la página',
  hello: 'Hola',
  hour: 'Hora',
  hourIn: 'Hora entrada',
  hourOut: 'Hora salida',
  id: 'Id',
  identifier: 'DNI',
  identifierDocument: 'Documento identificativo',
  inactive: 'inactiu',
  invoice: 'Albarán',
  kinship: 'Parentesco',
  language: {
    basque: 'Vasco',
    catalan: 'Catalán',
    english: 'Inglés',
    french: 'Francés',
    german: 'Alemán',
    italian: 'Italiano',
    spanish: 'Español',
  },
  loadingData: 'Obteniendo datos',
  locales: {
    CA: 'Catalán',
    EN: 'Inglés',
    ES: 'Español',
  },
  location: 'Lugar',
  logEntryDate: 'Fecha de entrada de registro',
  masterDataKo: 'Se ha producido un error al cargar los datos. Por favor, intentelo de nuevo.',
  month: 'Mes',
  more: 'más',
  name: 'Nombre',
  nationality: 'Nacionalidad',
  next: 'Siguiente',
  nie: 'Tarjeta de residencia',
  no: 'No',
  noItems: 'No hay elementos',
  notFound: 'No se ha encontrado',
  observation: 'Observación',
  observations: 'Observaciones',
  of: 'de',
  operatorComment: 'Observaciones operario',
  order: 'Orden',
  other: 'Otro',
  page: 'Página',
  pageSize: 'Tamaño de página',
  passport: 'Pasaporte',
  personalObjects: 'Objetos personales',
  phone: 'Teléfono',
  postalCode: 'Código postal',
  prev: 'Anterior',
  price: 'Precio',
  print: 'Imprimir',
  provider: 'Proveedor',
  province: 'Provincia',
  readQR: 'Leer código QR',
  remove: 'Eliminar',
  request: 'Solicitar',
  save: 'Guardar',
  search: 'Buscar',
  secondSurname: 'Segundo apellido',
  see: 'Ver',
  selectFile: 'Seleccionar archivo',
  send: 'Enviar',
  show: 'Mostrar',
  sign: 'Firmar',
  signText: 'Firme en el siguiente espacio',
  size: 'Tamaño',
  startDate: 'Fecha inicio',
  startHour: 'Hora inicio',
  status: 'Estat',
  street: 'Calle',
  sure: '¿Está seguro?',
  surname: 'Apellido',
  thePage: 'La página',
  type: 'Tipo',
  unsaved: 'Tienes cambios sin guardar, ¿estas seguro que quieres salir?',
  username: 'Nombre de usuario',
  validate: 'Validar',
  yes: 'Si',
};
export default common;
