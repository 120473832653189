import React, {
  FC, useCallback, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { useWhatsapp } from 'hooks/useWhatsapp';

import { SendWhatsappModalContent } from '../SendWhatsAppModalContent';

import { SendWhatsappModalComponent } from './types';

export const SendWhatsappModal: FC<SendWhatsappModalComponent> = ({
  show, number, onCancel, language, onSuccess, recordId,
}) => {
  const { t } = useTranslation();
  const { sendMessage } = useWhatsapp();
  const [loading, setLoading] = useState<boolean>(false);

  const send = useCallback(
    () => {
      sendMessage({
        language, number, recordId,
      })
        .then((response) => {
          onSuccess(response);
          return response;
        })
        .catch(() => false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language, number],
  );

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          onClick: () => {
            onCancel();
          },
          text: t('common.cancel'),
        },
        {
          disabled: loading,
          onClick: () => {
            send();
          },
          text: !loading ? t('common.send') : t('common.loadingData'),
        },
      ]}
      className="ia-modal"
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      title={t('record.whatsapp.title')}
      onHide={() => {
        onCancel();
      }}
    >
      {show && (
        <SendWhatsappModalContent
          number={number}
          recordId={recordId}
          onUpdateLoadingData={(adviceLoading) => {
            setLoading(adviceLoading);
          }}
        />
      )}
    </Modal>
  );
};
