import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { formatHour } from 'utils/dateManager';

import useCeremonyLocation from 'hooks/useCeremonyLocation';
import useDeposit from 'hooks/useDeposit';
import { useVigilLocation } from 'hooks/useVigilLocation';
import { CoffinTypes } from 'models/MasterService';
import { DeceasedPreparation } from 'models/OrderInfo';
import { RootState } from 'store';

import { DefaultInfoProps } from './Default';
import Info, { DetailType } from './Info';

const DeceasedPreparationInfo: FC<DefaultInfoProps> = ({
  expanded,
  loading,
  order,
}) => {
  const [details, setDetails] = useState<DetailType[]>([]);
  const { t } = useTranslation();
  const { normalized } = useSelector((state: RootState) => state);
  const { normalizedCeremonyLocations } = useCeremonyLocation(
    (order.info as DeceasedPreparation)?.ceremony?.address?.city,
  );
  const { normalizedVigilLocations } = useVigilLocation(
    (order.info as DeceasedPreparation)?.vigil?.address?.city,
  );
  const { normalizedDeposits } = useDeposit(
    (order.info as DeceasedPreparation)?.deposit?.address?.city,
  );

  useEffect(() => {
    const info: DeceasedPreparation = order.info as DeceasedPreparation;

    const newDetails: DetailType[] = [
      {
        text: t('order.details.creationHour'),
        value: formatHour(order.createdDate),
      },
      {
        text: t('record.deathData.diseases'),
        value: info?.death?.disease
          ? normalized.groupCauses[info.death.disease]?.description
          : undefined,
      },
      {
        text: t('record.deathData.deathCause'),
        value: info?.death?.cause,
      },
      {
        text: t('order.deposit'),
        value:
          info?.deposit?.deposit
          && normalizedDeposits[info?.deposit?.deposit]?.description,
      },
      {
        text: t('service.vigil.location'),
        value:
          info?.vigil?.location
          && normalizedVigilLocations[info?.vigil?.location]?.description,
      },
      {
        text: t('service.preparation.coffin'),
        value: t(
          `service.coffinType.${
            info?.coffin ? CoffinTypes.opened : CoffinTypes.closed
          }`,
        ),
      },
      {
        text: t('record.deceasedData.nickname'),
        value: info?.deceased?.nickname,
      },
      {
        text: t('service.preparation.coffinArticle'),
        value: info?.coffinArticle,
      },
      {
        text: t('service.preparation.coffinPreparationDetail'),
        value: info?.coffinPreparationDetail,
      },
      {
        text: t('service.preparation.aconditioning'),
        value: info?.aconditioning,
      },
      {
        text: t('service.preparation.creu'),
        value: info?.creu,
      },
      {
        text: `${t('common.comment')} ${t('service.preparation.creu')}`,
        value: info?.creuComment,
      },
      {
        text: t('service.preparation.personalObjects'),
        value: info?.personalItems,
      },
      {
        text: t('service.preparation.shaved'),
        value: info?.shaved,
      },
      {
        text: `${t('common.comment')} ${t('service.preparation.shaved')}`,
        value: info?.shavedComment,
      },
      {
        text: t('service.preparation.clothing'),
        value: info?.clothing,
      },
      {
        text: `${t('common.comment')} ${t('service.preparation.clothing')}`,
        value: info?.clothingComment,
      },
      {
        text: t('service.preparation.cover'),
        value: info?.cover,
      },
      {
        text: `${t('common.comment')} ${t('service.preparation.cover')}`,
        value: info?.coverComment,
      },
      {
        text: t('service.preparation.hairstyle'),
        value: info?.hairstyle,
      },
      {
        text: `${t('common.comment')} ${t('service.preparation.hairstyle')}`,
        value: info?.hairstyleComment,
      },
      {
        text: t('service.preparation.makeup'),
        value: info?.makeup,
      },
      {
        text: `${t('common.comment')} ${t('service.preparation.makeup')}`,
        value: info?.makeupComment,
      },
      {
        text: t('service.preparation.pacemaker'),
        value: info?.pacemaker,
      },
      {
        text: t('service.cremation.zincBox'),
        value: info?.zincBox,
      },
      {
        text: t('service.vigil.entryDate'),
        value: info?.vigil?.entryDate,
      },
      {
        text: t('service.vigil.entryTime'),
        value: info?.vigil?.entryTime,
      },
      {
        text: t('common.comment'),
        value: info?.comment,
      },
      {
        text: t('common.coordinatorComment'),
        value: info?.coordinatorComment,
      },
      {
        text: t('common.operatorComment'),
        value: info?.operatorComment,
      },
    ];

    setDetails(newDetails);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    normalizedVigilLocations,
    normalizedCeremonyLocations,
    normalizedDeposits,
    normalized,
  ]);

  return (
    <Info
      details={details}
      expanded={expanded}
      loading={loading}
      priority={order.priority}
    />
  );
};

export default DeceasedPreparationInfo;
